/* eslint-disable react-hooks/exhaustive-deps */
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import CardOpenBoxDashboard from "../../../components/CardOpenBoxDashboard";
import ConfirmationModal from "../../../components/ConfirmationModal";
import MasterKeyAuth from "../../../components/MasterKeyAuth";
import { _handleError } from "../../../helpers/errors";
import { _pushToastMessage } from "../../../helpers/messages";
import { set_loader } from "../../../redux/actions/loader";
import { set_modules } from "../../../redux/actions/modules";
import DoorLockerService from "../../../services/door_locker.service";
import DashboardTemplate from "../DashboardTemplate";
import LocalBoxService from "../../../services/local_box.service";

const DashboardHomeOffline = () => {
  const [masterKeyModal, setMasterKeyModal] = useState(false);
  const [box, setBox] = useState<any>(null);
  const [boxes, setBoxes] = useState<any>([]);
  const [confirmationModuleModal, setConfirmationModuleModal] = useState(false);
  const { session, modules, loader } = useSelector((state: any) => state);
  const dispatch: any = useDispatch();
  const DoorLocker = new DoorLockerService();
  const Box = new LocalBoxService();

  useEffect(() => {
    _checkModule();
  }, []);

  useEffect(() => {
    _getBoxes();
  }, []);

  const _checkModule = () => {
    const { profile } = session;
    if (modules.module_selected !== profile?.module?.slug) {
      setConfirmationModuleModal(true);
    }
  };

  const _getBoxes = async () => {
    try {
      const boxes = await Box.allBoxes();
      setBoxes(boxes.data);
    } catch (e: any) {
      _handleError(e, e.message);
    }
  };

  const _handleChangeModule = () => {
    const { profile } = session;
    dispatch(set_loader({ ...loader, is_loading: true }));
    dispatch(
      set_modules({ ...modules, module_selected: profile?.module?.slug })
    );
    _pushToastMessage({
      text: "Cambiando modulo... Aguarde el sistema reiniciar.",
      header: "Info",
      type: "info",
    });
    setTimeout(() => {
      dispatch(set_loader({ ...loader, is_loading: false }));
      window.location.href = "/";
    }, 3000);
  };

  const _handleOpenBox = (payload: any) => {
    setBox(payload);
    _handleConfirmMasterKey(payload);
  };

  const _handleConfirmMasterKey = async (payload: any) => {
    try {

      const data: any = {
        com: session?.profile?.door_com_number,
        driveboard: payload?.desk_number,
        door: payload?.desk_door_number,
        box: payload,
      };

      const response = await DoorLocker.openDoor(data);
      if (response.data.status === "error") {
        throw new Error("No fue posible abrir esta puerta.");
      }

      _pushToastMessage({
        header: "Éxito",
        type: "success",
        text: "Caja abierta con exito",
      });
      dispatch(set_loader({ is_loading: false }));
      setMasterKeyModal(false);
    } catch (e: any) {
      dispatch(set_loader({ is_loading: false }));
      if (e.message === "Network Error") {
        _handleError(
          e,
          "No fue posible conectarse a la api del terminal. Revisa la api y los COM's."
        );
      } else {
        _handleError(e, e.message);
      }
    }
  };

  return (
    <DashboardTemplate>
      <MasterKeyAuth
        open={masterKeyModal}
        title="Informe su master key"
        handleClose={() => setMasterKeyModal(false)}
        handleConfirm={(payload: any) => _handleConfirmMasterKey(payload)}
        description={`El usuario {USER} abrió la puerta ${box?.door_number} del locker {TERMINAL} al dia {DATETIME} con la key {MASTERKEY}`}
        action="OPEN_DOOR"
      />

      <ConfirmationModal
        open={confirmationModuleModal}
        type="warning"
        headerText="Módulos"
        contentText="El módulo asociado a este terminal no es el seleccionado en los ajustes del Locker. ¿Quieres sincronizar el módulo?"
        onCancel={() => setConfirmationModuleModal(false)}
        onConfirm={_handleChangeModule}
      />
      <div className="w-100 row px-4 py-2 mx-0 mb-4 justify-content-around">
        <div
          className="col-12 my-2 pb-2 size-12"
          style={{ borderBottom: "2px solid #afafaf", fontWeight: "bold" }}
        >
          CAJAS
        </div>
        {boxes?.map((box: any) => (
          <CardOpenBoxDashboard
            door_number={box.door_number}
            _handleOpenBox={_handleOpenBox}
            status={box?.is_busy ? "Ocupado" : "Libre"}
            key={box.id}
            data={box}
          />
        ))}
      </div>
    </DashboardTemplate>
  );
};

export default DashboardHomeOffline;
