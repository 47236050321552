/* eslint-disable react-hooks/exhaustive-deps */
import Header from "../../components/Header";
// import { ReactComponent as Delivery } from "../../../../assets/svg/package-box.svg";
// import ItemAction from "../../components/ItemAction";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { set_loader } from "../../../../redux/actions/loader";
import HiddenQrRreader from "../../components/HiddenQrReader";
import Drawer from "../../../../components/Drawer";
import NumberKeyboard from "../../components/NumberKeyboard";
import { useNavigate } from "react-router-dom";
import { _pushToastMessage } from "../../../../helpers/messages";

const Home = () => {
  const [deliveryDrawer, setDeliveryDrawer] = useState({
    open: false,
    data: {},
  });

  const navigate = useNavigate();

  const dispatch: any = useDispatch();
  useEffect(() => {
    const timeout: any = setTimeout(() => {
      dispatch(set_loader({ is_loading: false }));
      console.log("CLOSE LOADER");
    }, 5000);

    return () => {
      clearInterval(timeout);
      console.log("CLEAR INTERVAL");
    };
  }, []);

  const _handleOnConfirmKeyboard = (apartament: number) => {
    if (!apartament) {
      _pushToastMessage({
        type: "warning",
        text: "Ingrese un número de departamento / casa.",
        header: "Aviso",
      });
      return;
    }
    navigate(`/delivery-with-apartament/${apartament}`, { replace: true });
  };

  return (
    <div className="container-fluid h-100">
      <Header />
      <HiddenQrRreader />
      <Drawer
        isOpen={deliveryDrawer.open}
        onClose={() => setDeliveryDrawer({ open: false, data: {} })}
      >
        <NumberKeyboard
          isOpen={deliveryDrawer.open}
          onConfirm={_handleOnConfirmKeyboard}
        />
      </Drawer>

      <div className="content-section">
        <div className="row justify-content-center my-5 align-items-center" style={{height: '400px'}}>
          <div className="w-75 text-center mt-5">
            <h1 className="bold">PARA RETIRAR ESCANEA EL QR DE TU DOCUMENTO DE IDENTIDAD</h1>
          </div>
        </div>
        {/* <div className="row align-items-center justify-content-center mt-5">
          <ItemAction
            label="Devolución"
            Image={Delivery}
            description="Presiona aquí para devolver."
            handleOnClick={() =>
              navigate("/delivery-articles", { replace: true })
            }
          />
        </div> */}
      </div>
    </div>
  );
};

export default Home;
