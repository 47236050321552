import { handleActions } from "redux-actions";
import { SET_MODULES, CLEAR_MODULES, RELOAD_MODULES } from "../actions/modules";

export const INITIAL_STATE = {
  modules_list: [
    {
      name: "Modulo Residencial",
      slug: "residential-module",
    },
    {
      name: "Modulo Residencial Offline",
      slug: "residential-offline-module",
    },
    {
      name: "Modulo Pickup",
      slug: "pickup-module",
    },
    {
      name: "Modulo Lider",
      slug: "lider-module",
    },
    {
      name: "Modulo Pickup Box",
      slug: "pickup-box-module",
    },
    {
      name: "Modulo Unimarc",
      slug: "unimarc-module",
    },
    {
      name: "Modulo Las Condes",
      slug: "lascondes-module",
    },
    {
      name: "Modulo Cubo Residencial",
      slug: "residential-cubo-module",
    },
    {
      name: "Modulo Servicios - Ecommerce - Sameday",
      slug: "only-services-module",
    },
    {
      name: "Prestamos",
      slug: "lending-module",
    },
    {
      name: "Prestamos Unab",
      slug: "lending-unab-module",
    }
  ],
  module_selected: "residential-module",
};

export default handleActions(
  {
    [SET_MODULES]: (state: any, action: any) => {
      return { ...state, ...action.payload };
    },
    [RELOAD_MODULES]: (state: any) => {
      return { ...INITIAL_STATE, module_selected: state.module_selected };
    },
    [CLEAR_MODULES]: () => {
      return { ...INITIAL_STATE };
    },
  },
  INITIAL_STATE
);
